<template>
  <v-avatar :class="{ profile: true, 'elevation-6': shadow }" :size="size">
    <v-img
      :src="selectedFile ? selectedImage : image ? image : placeholderImage"
      alt="Avatar"
    />
    <v-btn
      color="#0a5b8a"
      class="edit_button"
      fab
      dark
      x-small
      :loading="isSelectingPhoto"
      @click="onEditButtonClick"
      v-if="editable"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="image/*"
      @change="onFileChanged"
    />
  </v-avatar>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: "",
    },
    onImageSelected: {
      type: Function,
      default: () => {}, // Prevents "is not a function" error
    },
    editable: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 100,
    },
    shadow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedFile: null,
      placeholderImage: require("../assets/default.jpg"),
      isSelectingPhoto: false,
      selectedImage: null,
    };
  },
  methods: {
    onEditButtonClick() {
      if (!this.$vuetify.breakpoint.xs) this.isSelectingPhoto = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingPhoto = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      if (e.target.files.length !== 0) {
        this.selectedFile = e.target.files[0];
        this.selectedImage = URL.createObjectURL(this.selectedFile);
        this.onImageSelected(this.selectedFile);
      }
    },
  },
};
</script>

<style scoped>
.profile {
  overflow: visible;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
</style>
